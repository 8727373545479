import React from 'react'
import './SalesMainChapter.css'
import { Icon } from '@ui5/webcomponents-react'
import '@ui5/webcomponents-icons/dist/kpi-corporate-performance.js';
import '@ui5/webcomponents-icons/dist/sys-cancel.js';
import '@ui5/webcomponents-icons/dist/multiselect-none.js';
import '@ui5/webcomponents-icons/dist/complete.js';
import '@ui5/webcomponents-icons/dist/accept.js';

const SalesMainChapterCard = (props) => {
    return (
        <div
            onClick={() => {
                props.setChapter(props.chapter);
            }}
            className="SalesMainChapterCard">
            <Icon design='Information' className='SalesMainChapterCard__icon' name={props.icon} />
            <h3 className='SalesMainChapterCard__title'>{props.title}</h3>
            <p className='SalesMainChapterCard__text'>{props.description}</p>
        </div>
    )
}

const SalesMainChapter = (props) => {
    return (
        <div className='sales-main-container'>
            <div className="sales-main-subcontainer">
                <SalesMainChapterCard
                    setChapter={props.setChapter}
                    chapter="all"
                    icon="multiselect-none"
                    title="Входящие заявки"
                    description="Входящие заявки"
                />
                <SalesMainChapterCard
                    setChapter={props.setChapter}
                    chapter="confirmed"
                    icon="complete"
                    title="Подтвержденные сделки"
                    description="Подтвержденные сделки"
                />
                <SalesMainChapterCard
                    setChapter={props.setChapter}
                    chapter="refused"
                    icon="sys-cancel"
                    title="Отказанные"
                    description="Отказанные"
                />
            </div>
            <SalesMainChapterCard
                setChapter={props.setChapter}
                chapter="statistic"
                icon="kpi-corporate-performance"
                title="Статистика"
                description="Статистика"
            />
        </div>
    )
}

export default SalesMainChapter