import axios_instance from "../../../../../../requests/config_defaults"
import React, { useState, useEffect } from "react"
import { Button, StepInput } from "@ui5/webcomponents-react"

const StepInputCount = (props) => {
  const [needCount, setNeedCount] = useState(props?.total_product_count)
  const [isEditing, setIsEditing] = useState(false)
  const [chosenPVZ, setChosenPVZ] = useState(null)

  const removeFromProjectOrderManager = async (id) => {
    await axios_instance
      .delete("/orders/order_manager/remove/" + id)
      .then(({ data }) => {
      })
      .catch((error) => {
      });
  };

  const fcsCalculateOnEdit = async (id, count, isPVZ) => {
    if (count > 0) {
      await axios_instance
        .post(`/fcs/calculate/stock/on_edit/` + id, {
          count: count,
          is_pvz: isPVZ,
        })
        .then(({ data }) => {
        })
        .catch((error) => {
        });
    }
  };

  // const setOrderManagerPVZ = async (order_manager_id, pvz_value) => {
  //   await axios_instance
  //     .put(`/orders/order_manager/set/pvz/${order_manager_id}`, {
  //       pvz: pvz_value,
  //     })
  //     .then(({ data }) => {
  //     })
  //     .catch((error) => {
  //     });
  // };

  const countChangeHandler = async () => {
    if (needCount === props.total_product_count) {
      return null
    }
    else if (needCount === 0) {
      props?.showToast()
      props?.updateProject(props?.project_id)
      props?.setActiveOrderProject(props?.project_id)
      props?.setUserState("orderProject")
      props?.managers.map((manager) => {
        removeFromProjectOrderManager(manager.id)
      })
      props?.delayTimeout(props?.project_id)
    }
    else if (needCount > props.total_product_count) {
      props?.addOrderItemToProject(props?.project_id, needCount - props?.total_product_count)
      props?.setActiveOrderProject(props?.project_id)
      props?.setUserState("orderProject")
      props.updateProject(props?.project_id)
    }
    else if (needCount < props.total_product_count) {
      props?.showToast()
      props?.updateProject(props?.project_id)
      props?.setActiveOrderProject(props?.project_id)
      props?.setUserState("orderProject")
      let productCount = props.total_product_count - needCount
      props?.managers.map((manager) => {
        if (productCount === 0) {
          return null
        }
        else if (productCount >= manager.count) {
          removeFromProjectOrderManager(manager.id)
          productCount -= manager.count
        }
        else if (productCount < manager.count) {
          fcsCalculateOnEdit(manager.id, manager.count - productCount, manager.is_pvz_delivery)
          productCount = 0
          if (manager.is_pvz_delivery) {
            setChosenPVZ(manager.chosen_pvz)
          }
        }
      })
      if (chosenPVZ) {
        props?.delayTimeout(props?.project_id, chosenPVZ)
      } else {
        props?.delayTimeout(props?.project_id)
      }
    };
    setChosenPVZ(null)
  }

  return (
    <div style={{ display: "flex", gap: "5px" }}>
      <StepInput
        onChange={(e) => {
          setNeedCount(e.detail.value);
          setIsEditing(true);
        }}
        style={{ width: 150 }}
        min={0}
        value={needCount}
      />
      {isEditing && (
        <>
          <Button
            disabled={needCount < 0}
            onClick={() => {
              setIsEditing(false)
              props.setCount(needCount)
              props?.setOrderProjectName(props?.project_name)
              countChangeHandler()
            }}
            tooltip="Применить изменения"
            style={{ color: "green" }}
            icon="accept"
          />
          <Button
            onClick={() => {setIsEditing(false); setNeedCount(props?.total_product_count)}}
            tooltip="Отменить изменения"
            style={{ color: "red" }}
            icon="decline" 
          />
        </>
      )}
    </div>
  )
}

export default StepInputCount
