import { SideNavigation, SideNavigationItem, SideNavigationSubItem } from '@ui5/webcomponents-react'
import React from 'react'
import '@ui5/webcomponents-icons/dist/home.js';
import '@ui5/webcomponents-icons/dist/kpi-corporate-performance.js';
import '@ui5/webcomponents-icons/dist/sys-cancel.js';
import '@ui5/webcomponents-icons/dist/multiselect-none.js';
import '@ui5/webcomponents-icons/dist/complete.js';
import '@ui5/webcomponents-icons/dist/developer-settings.js';
import { useSearchParams } from 'react-router-dom';

const SalesSideNavigation = (props) => {
    const [searchParams, setSearchParams] = useSearchParams();
    return (
        <SideNavigation
            style={{
                width: "100% !important",
            }}
            header={
                <h1
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%!important",
                        fontSize: "1.5rem",
                    }}
                >Продажи</h1>
            }
            onSelectionChange={(e) => {
                props.setChapter(e.detail?.item?.dataset?.chapter);
                setSearchParams((prev) => ({...prev, chapter: e.detail?.item?.dataset?.chapter}))
            }}
        >
            <SideNavigationItem
                selected={props.chapter === "main"}
                data-chapter="main"
                icon="home"
                text="Главная"
            />
            {props.isAdmin && <SideNavigationItem
                selected={props.chapter === "premoderation"}
                data-chapter="premoderation"
                icon="developer-settings"
                text="Премодерация"
            />}
            <SideNavigationItem
                selected={props.chapter === "all"}
                data-chapter="all"
                icon="multiselect-none"
                text="Входящие"
            />
            <SideNavigationItem
                selected={props.chapter === "confirmed"}
                data-chapter="confirmed"
                icon="complete"
                text="Подтвержденные"
                expanded
            >
                <SideNavigationSubItem
                    selected={props.chapter === "part_confirmed"}
                    data-chapter="part_confirmed"
                    text="Частично подтвержденные"
                />
            </SideNavigationItem>
            {/* <SideNavigationItem
                selected={props.chapter === "part_confirmed"}
                data-chapter="part_confirmed"
                icon="accept"
                text="Частично подтвержденные"
            /> */}
            <SideNavigationItem
                selected={props.chapter === "refused"}
                data-chapter="refused"
                icon="sys-cancel"
                text="Отказанные"
            />
            <SideNavigationItem
                selected={props.chapter === "statistic"}
                data-chapter="statistic"
                icon="kpi-corporate-performance"
                text="Статистика"
            />
        </SideNavigation>
    )
}

export default SalesSideNavigation