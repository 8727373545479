import React, {useEffect, useState} from "react";
import "./UserWizard.css";
import {checkIsAuthenticated} from "../../../requests/authorization";
import {
    Bar,
    Button,
    Card,
    IllustratedMessage,
    Loader,
    ThemeProvider,
    Select,
    Option,
    FlexBox, Input,
} from "@ui5/webcomponents-react";
import {useAtom} from "jotai";
import {orderProjectAtom, orderProjectRefresherAtom, userStateAtom} from "./UserWizardAtoms";
import {activeCompanyAtom} from "../../../hoc/GlobalAtoms";
import Authorization from "../../authorization/Authorization";
import Registration from "../../registration/Registration";
import CompanyCreateForm from "../../companies-view/company-create-form/CompanyCreateForm";
import CompanyAddressCreateForm from "../../companies-view/company-address-create-form/CompanyAddressCreateForm";
import OrderProjectInfoSide from "../../orders-view/order-project-info-side/OrderProjectInfoSide";
import axios_instance from "../../../requests/config_defaults";

const UserWizard = ({
                        setIsAuthenticated,
                        setActiveAddress,
                        setAddressesRefresher,
                        setIsOpen
                    }) => {
    const [isAuth, setIsAuth] = useState(false);
    const [loading, setIsLoading] = useState(true);
    const [userState, setUserState] = useAtom(userStateAtom);
    const [activeCompany] = useAtom(activeCompanyAtom);
    const [addresses, setAddresses] = useState([]);
    // const [activeAddress, setActiveAddress] = useState("");
    const [newAddressCreateOpen, setNewAddressCreateOpen] = useState(false);
    const [orderCardOpen, setOrderCardOpen] = useState(false);
    const [orderProject, setOrderProject] = useAtom(orderProjectAtom);
    const [orderProjectInfoSideOpen, setOrderProjectInfoSideOpen] =
        useState(false);
    const [orderProjectData, setOrderProjectData] = useState("");
    const [orderProjectRefresher, setOrderProjectRefresher] = useAtom(orderProjectRefresherAtom);

    const isAuthenticated = async () => {
        let status = await checkIsAuthenticated();
        status === true
            ? setUserState("completed")
            : setUserState("not_authenticated");
        setIsAuthenticated(status);
        setIsLoading(false);
    };

    useEffect(() => {
        if (userState === "orderCard" && addresses.length !== 0) {
            setUserState("completed");
        }
    }, [activeCompany]);

    useEffect(() => {
        isAuthenticated();
    }, [isAuth]);

    const createOrderProject = async () => {
        await axios_instance
            .post(`/orders/order_project/create`, {name: orderProjectData})
            .then(({data}) => {
                setOrderProjectRefresher((prev) => !prev)
                setOrderProjectData("")
                setUserState("completed");
                setIsOpen(false)
            })
            .catch((error) => {

            });
    };

    const states = {
        not_authenticated: (
            <Authorization
                onRegClick={() => {
                    setUserState("registration");
                }}
                onSuccess={() => {
                    setUserState("completed");
                }}
            />
        ),
        registration: (
            <Registration
                onCancel={() => {
                    setUserState("not_authenticated");
                }}
            />
        ),
        completed:
            activeCompany?.id === undefined ? (
                <ThemeProvider>
                    {" "}
                    {<CompanyCreateForm searchPage={"searchPage"}/>}{" "}
                </ThemeProvider>
            ) : newAddressCreateOpen === false ? (
                <></>
            ) : (
                <></>
            ),
        addressCreate: (
            <ThemeProvider>
                <CompanyAddressCreateForm
                    onSuccess={() => {
                        setAddressesRefresher((prev) => !prev);
                        setUserState("orderCard");
                    }}
                    setUserState={setUserState}
                    activeCompany={activeCompany?.id}
                    searchPage={
                        addresses?.length !== 0 ? "searchPage" : "searchPageFirst"
                    }
                    setNewAddressCreateOpen={setNewAddressCreateOpen}
                />
            </ThemeProvider>
        ),
        orderCard: (
            <ThemeProvider>
                <FlexBox
                    style={{
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                    }}
                >
                    <OrderProjectInfoSide
                        searchPage={"searchPage"}
                        setOpenProject={setOrderCardOpen}
                    />
                </FlexBox>
            </ThemeProvider>
        ),
        orderProject: (
            <ThemeProvider>
                <OrderProjectInfoSide
                    id={orderProject}
                    activeCompany={activeCompany.id}
                    onClose={() => {
                        setOrderProject(null);
                        setUserState("orderCard");
                    }}
                    state={orderProjectInfoSideOpen}
                    setState={setOrderProjectInfoSideOpen}
                    setAddressesData={setAddresses}
                    addressesData={addresses}
                    setOpenProject={() => {
                        // setOrderProject(null);
                        // setUserState("orderCard");
                    }}
                    openProject={true}
                />
            </ThemeProvider>
        ),
        orderProjectCreate: (
            <ThemeProvider>
                <div className="UserWizardProjectCreateForm-modal-main">
                    <div className="UserWizardProjectCreateForm-modal-container">
                        <div className="UserWizardProjectCreateForm-modal-content-header">
                            {activeCompany?.name}
                        </div>
                        <div className="UserWizardProjectCreateForm-modal-content">
                            <h1>Создание проекта заказа</h1>
                            <FlexBox style={{gap: "1rem", alignItems: "center"}}>
                                <Input placeholder="Введите наименование" onChange={(e) => setOrderProjectData(e.target.value)}>
                                    Название проекта
                                </Input>
                                <Button onClick={() => createOrderProject()}>Создать</Button>
                            </FlexBox>
                        </div>
                    </div>
                </div>
            </ThemeProvider>
        ),
    };

    return (
        <>
            {!userState ? (
                <div
                    style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <ThemeProvider>
                        <Loader size={50}/>
                    </ThemeProvider>
                </div>
            ) : (
                <div className="UserWizard__main__container">{states[userState]}</div>
            )}
        </>
    );
};

export default UserWizard;
