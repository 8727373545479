import React, { useEffect, useState, useRef } from "react";
import {
  Badge,
  Button,
  FlexBox,
  Input,
  Label,
  Option,
  MessageBox,
  Select,
  StepInput,
  Switch,
  Toast,
} from "@ui5/webcomponents-react";
import Table from "../../../../base/table/Table";
import { MenuItem, ThemeProvider } from "@mui/material";
import { activeCompanyAtom } from "../../../../../hoc/GlobalAtoms";
import { useAtom } from "jotai";
import axios_instance from "../../../../../requests/config_defaults";
import { Loader, Modal } from "@mantine/core";
import "./OrderProjectModal.css";
import {
  orderProjectAtom, orderProjectRefresherAtom,
  userStateAtom,
} from "../../../user-wizard/UserWizardAtoms";
import StepInputCount from "./StepInputCount/StepInputCount"
import usePvzSearcher from "../../../../../hooks/PvzSearcher";

const fields = {
  need_count: {
    key: "need_count",
    header: "Изменить количество",
    editable: false,
  },
  number: {
    key: "number",
    header: "Номер заказа",
    editable: false,
  },
  order_custome_name: {
    key: "order_custome_name",
    header: "Название заказа",
    editable: false,
  },
  project_cost: {
    key: "project_cost",
    header: "Стоимость заказа",
    editable: false,
  },
  user: {
    key: "user",
    header: "Создатель",
    editable: false,
  },
  contractor: {
    key: "contractor",
    header: "Покупатель",
    editable: false,
  },
  order_create_datetime_stamp: {
    key: "order_create_datetime_stamp",
    header: "Дата создания",
    editable: false,
  },
  order_product_count: {
    key: "order_product_count",
    header: "Количество товаров в заказе",
    editable: false,
  },
  order_item_count: {
    key: "order_item_count",
    header: "Количество позиций в заказе",
    editable: false,
  },
};

const OrderProjectModal = (props) => {
  const toast = useRef(null);
  const toastFixedProjects = useRef(null);
  const [activeCompany] = useAtom(activeCompanyAtom);
  const [orderProjects, setOrderProjects] = useState(null);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [loading, setLoading] = useState(false);
  const [columnFilters, setColumnFilters] = useState([]);
  const [orderProjectData, setOrderProjectData] = useState("");
  const [isPVZ, setIsPVZ] = useState(false);
  const [orderProjectName, setOrderProjectName] = useState("");
  const [userState, setUserState] = useAtom(userStateAtom);
  const [activeOrderProject, setActiveOrderProject] = useAtom(orderProjectAtom);
  const [orderProjectRefresher] = useAtom(orderProjectRefresherAtom);
  const [count, setCount] = useState(0);
  const [projectRemove, setProjectRemove] = useState(false);

  const { pvzSearcher, pvzValue, pvzSetValue, geoObjectSet, aggregatorSet } =
    usePvzSearcher(
      {},
      "",
      "b605d4ce-28d3-4d81-978a-467709d7932b",
      props?.geoObjectData.id
    );

  const [needCountToFixedProject, setNeedCountToFixedProject] = useState(0);
  const [rowPinning, setRowPinning] = useState([]);

  const showToast = () => {
    toast.current.show();
  };

  const showToastFixedProjects = () => {
    toastFixedProjects.current.show();
  };

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };

  const addOrderItemToProject = async (project_id, addedCount) => {
    await axios_instance
      .post("/fcs/calculate/stock", {
        order_project: project_id,
        product: props.product,
        count: addedCount ? addedCount : needCountToFixedProject,
        endpoint: props?.address,
        is_pvz: isPVZ,
      })
      .then((response) => {
        if (addedCount) {
          showToast()
        }
        if (isPVZ) {
          delayTimeout(project_id, pvzValue)
        } else {
          delayTimeout(project_id)
        }
      })
      .catch((error) => {
      })
  }

  const removeFromProject = async (id) => {
    await axios_instance
      .delete("/orders/order_project/remove/" + id)
      .then(({ data }) => {
        filterOrderProjects();
      })
      .catch((error) => {
      });
  };

  const filterOrderProjects = async () => {
    setLoading(true);
    await axios_instance
      .post(
        `/orders/order_project/filter?page=${page}${`&filters=${JSON.stringify(
          columnFilters ?? []
        )}`}`,
        { status: "NOT FORMED" }
      )
      .then(({ data }) => {
        setPageCount(
          data?.count % 20 === 0
            ? data?.count / 20
            : Math.floor(data?.count / 20) + 1
        );
        const order_projects = [];
        data?.results.map((project) => {
          let total_product_count = 0
          if (project.product_total_sum.hasOwnProperty(props.product)) {
            total_product_count = project.product_total_sum[props.product];
          }
          let managers = []
          project.managers.map((manager) => {
            if (manager.product === props.product) {
              managers.push(manager);
            }
          })
          order_projects.push({
            id: project.id,
            need_count:
              <StepInputCount
                addOrderItemToProject={addOrderItemToProject}
                total_product_count={total_product_count}
                managers={managers}
                product={props.product}
                project_id={project.id}
                project_name={project?.name}
                setActiveOrderProject={setActiveOrderProject}
                showToast={showToast}
                setCount={setCount}
                setUserState={setUserState}
                setOrderProjectName={setOrderProjectName}
                delayTimeout={delayTimeout}
                updateProject={updateProject}
              />,
            order_custome_name: project.name,
            number: project.number,
            user: project.user,
            contractor: project.contractor.name,
            order_create_datetime_stamp: project.create_date_time_stamp,
            order_product_count: project.product_count,
            order_item_count: project.order_item_count,
            project_cost: Number(project.project_cost).toFixed(2),
          });
        });
        setOrderProjects(order_projects);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  // useEffect(() => {
  //   console.log(pvzValue)
  // }, [pvzValue])

  // useEffect(() => {
  //   console.log(isPVZ)
  // }, [isPVZ])

  const setOrderManagerPVZ = async (order_manager_id, pvz_value) => {
    await axios_instance
      .put(`/orders/order_manager/set/pvz/${order_manager_id}`, {
        pvz: pvz_value?.id,
      })
      .then(({ data }) => {
      })
      .catch((error) => {
      });
  };

  const getProjectByID = async (project_id, pvz_value) => {
    await axios_instance
      .get("/orders/order_project/managers/get/by_id/" + project_id)
      .then(({ data }) => {
        let total_product_count = 0
        if (data.product_total_sum.hasOwnProperty(props.product)) {
          total_product_count = data.product_total_sum[props.product];
        }
        let managers = []
        data.filtered_managers.map((manager) => {
          if (manager.product == props.product) {
            managers.push(manager);
          }
        })
        setOrderProjects(prevProjects =>
          prevProjects.map(project => 
            project.id === project_id
              ? {
                  ...project,
                  order_product_count: data?.product_count,
                  order_item_count: data?.order_item_count,
                  project_cost: Number(data?.project_cost).toFixed(2),
                  need_count:
                    <StepInputCount
                      addOrderItemToProject={addOrderItemToProject}
                      total_product_count={total_product_count}
                      managers={managers}
                      product={props.product}
                      project_id={data.id}
                      project_name={data?.name}
                      setActiveOrderProject={setActiveOrderProject}
                      showToast={showToast}
                      setCount={setCount}
                      setUserState={setUserState}
                      setOrderProjectName={setOrderProjectName}
                      delayTimeout={delayTimeout}
                      updateProject={updateProject}
                    />,
                }
              : project
          )
        )
        if (pvz_value) {
          setOrderManagerPVZ(managers[0].id, pvz_value);
        }
      })
      .catch((error) => {
      });
  };

  const delayTimeout = async (project_id, pvz_value) => {
    const timeout = setTimeout(()=> {
      if (pvz_value) {
        getProjectByID(project_id, pvz_value);
      } else {
        getProjectByID(project_id);
      }
      clearTimeout(timeout);
    }, 10000)
  }

  const createOrderProject = async () => {
    setOpenModal(false);
    await axios_instance
      .post(`/orders/order_project/create`, { name: orderProjectData })
      .then(({ data }) => {
        filterOrderProjects();
      })
      .catch((error) => {
        filterOrderProjects();
      });
  };

  const [openModal, setOpenModal] = useState(false);

  const createOrderProjectButtonHandler = () => {
    setUserState("orderProjectCreate");
    props.setIsOpen(true)
  };

  const closeModalHandler = () => {
    setOpenModal(false);
  };

  const updateProject = (project_id) => {
    setOrderProjects(prevProjects =>
      prevProjects.map(project => 
        project.id === project_id
          ? {
              ...project,
              order_product_count: <Loader size={20} />,
              order_item_count: <Loader size={20} />,
              project_cost: <Loader size={20} />,
              need_count: <Loader size={20} />,
            }
          : project
      )
    )
  };

  useEffect(() => {
    filterOrderProjects();
  }, []);

  useEffect(() => {
    filterOrderProjects();
    setRowPinning([]);
  }, [page, columnFilters, activeCompany, orderProjectRefresher]);

  useEffect(() => {
    setPage(1);
  }, [columnFilters]);

  return (
    <>
      <div className="order-project-content">
        <div
          className="order-project-table-bounder"
          style={{
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            height: "100%",
            width: "100%",
          }}
        >
          <Toast
            duration={10000}
            ref={toast}
            style={{ color: "black" }}
          >
            Количество товара изменено в проекте заказа 
            <span style={{ color: "green" }}> "{orderProjectName}." </span> Текущее количество 
            <span style={{ color: "green" }}> {count}шт. </span> Выполняется перерасчёт
          </Toast>
          <Toast
            duration={10000}
            ref={toastFixedProjects}
            style={{ color: "black" }}
          >
            Товар успешно добавлен в закрепленные проекты заказа в количестве 
            <span style={{ color: "green" }}> {needCountToFixedProject}шт. </span> Выполняется перерасчет
          </Toast>
          {projectRemove && (
            <>
              <div
                className="AdminCatalogUpadate-overlay"
                id="AdminCatalogUpadate-overlay"
              ></div>
              <MessageBox
                open={true}
                type="Confirm"
                onClose={() => {
                  setProjectRemove(false);
                }}
                actions={[
                  <Button
                    design="Emphasized"
                    onClick={() => {
                      removeFromProject(projectRemove);
                      setProjectRemove(false);
                      setActiveOrderProject();
                      setUserState("orderProjectCreate");
                    }}
                  >
                    Удалить
                  </Button>,
                  <Button
                    onClick={() => {
                      setProjectRemove(false);
                    }}
                  >
                    Отмена
                  </Button>,
                ]}
              >
                Нажмите "Удалить", чтобы подтвердить удаление.
              </MessageBox>
            </>
          )}
          {orderProjects ? (
            <Table
              className={"order-projects-view-table"}
              columns={fields}
              data={orderProjects}
              editDisplayMode="cell"
              enableEditing={false}
              enablePagination={false}
              enableColumnFilters={true}
              enableColumnOrdering={true}
              enableGrouping={false}
              enableColumnPinning={true}
              enableFacetedValues={false}
              enableColumnResizing
              enableRowActions={true}
              onColumnFiltersChange={setColumnFilters}
              onRowPinningChange={setRowPinning}
              rowPinning={rowPinning}
              enableStickyHeader={true}
              isLoading={loading}
              topToolbarCustomActions={
                <>
                  <div
                    style={{
                      display: "flex",
                      gap: "1rem",
                      alignItems: "center",
                      width: "auto"
                    }}
                  >
                    <Button
                      tooltip="Создать проект заказа"
                      style={{ width: "inherit" }}
                      icon="add"
                      onClick={() => createOrderProjectButtonHandler()}
                    >
                      Создать проект заказа
                    </Button>
                    {/* <Input
                      style={{ width: "100%" }}
                      valueState={
                        needCount <= 0
                          ? "Error"
                          : needCount
                          ? "Success"
                          : "None"
                      }
                      placeholder={
                        needCount <= 0
                          ? "Значение меньше 0"
                          : !needCount && "Введите необходимое количество"
                      }
                      onInput={(e) => {
                        setAddToOrderProjectStatus(false);
                        setNeedCount(parseInt(e.target.value));
                      }}
                    /> */}
                    <div
                      className="switch-content"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "0.5rem",
                      }}
                    >
                      <Label>ПВЗ</Label>
                      <Switch
                        design="Textual"
                        checked={isPVZ}
                        textOff="Нет"
                        textOn="Да"
                        onChange={() => {
                          setIsPVZ(!isPVZ);
                        }}
                      />
                      {isPVZ && (
                        <FlexBox>{pvzSearcher}</FlexBox>
                      )}
                    </div>
                    {rowPinning?.top?.length > 0 &&
                      <>
                        <div
                          className="order-project-table-fixed"
                        >
                          <text>Добавить в закрепленные:</text>
                          <StepInput 
                            className="order-project-table-step-input"
                            onChange={(e) => {
                              setNeedCountToFixedProject(e.detail.value);
                            }}
                            min={0}
                            value={needCountToFixedProject}
                          />
                        </div>
                        <div
                          className="switch-content"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "0.5rem",
                          }}
                        >
                          {(needCountToFixedProject > 0 && ((pvzValue && isPVZ) || !isPVZ)) ?
                            <Button
                              onClick={() => {
                                orderProjects?.map((order, index) => {
                                  if (rowPinning?.top.includes(index.toString())) {
                                    showToastFixedProjects();
                                    updateProject(order.id);
                                    addOrderItemToProject(order.id);
                                  }
                                });
                              }}
                              tooltip="Добавить в закрепленные проекты заказа"
                              style={{ color: "green" }}
                              icon="accept"
                            />
                          : null}
                        </div>
                      </>
                    }
                  </div>
                </>
              }
              pagination={{
                page: page,
                setPage: setPage,
                pageCount: pageCount,
              }}
              rowActionMenuItems={({ row, closeMenu }) => [
                <MenuItem
                  key="view"
                  onClick={() => {
                    openInNewTab(`/orders?project=${row?.original?.id}`)
                    closeMenu();
                  }}
                >
                  Открыть проект заказа в новой вкладке
                </MenuItem>,
                <MenuItem
                  key="view"
                  onClick={() => {
                    updateProject(row?.original?.id)
                    delayTimeout(row?.original?.id)
                    closeMenu();
                  }}
                >
                  Обновить проект заказа
                </MenuItem>,
                <MenuItem
                  key="view"
                  onClick={() => {
                    setProjectRemove(row?.original?.id)
                    closeMenu();
                  }}
                >
                  Удалить проект заказа
                </MenuItem>,
              ]}
            />
          ) : (
            <Loader size={100} />
          )}
        </div>
      </div>
    </>
  );
};

export default OrderProjectModal;
