import React, { useEffect, useMemo, useState } from "react";
import axios_instance from "../../../../requests/config_defaults";
import {
  ActionSheet,
  Label,
  BusyIndicator,
  Button,
  FlexBox,
  Form,
  FormGroup,
  FormItem,
  Input,
  Menu,
  MenuItem,
  ObjectPageSection,
  ObjectPageSubSection,
  StepInput,
  Text,
  MessageStrip,
  Badge,
  Panel,
  Timeline,
  TimelineItem,
  Switch,
  Icon,
} from "@ui5/webcomponents-react";
import {
  Paper,
  TableContainer,
  createTheme,
  useTheme,
  ThemeProvider as MuiThemeProvider,
  Box,
} from "@mui/material";
import { grey, lime, purple } from "@mui/material/colors";
import { MaterialReactTable } from "material-react-table";
import { MRT_Localization_RU } from "material-react-table/locales/ru";
import "@ui5/webcomponents-icons/dist/paging.js";
import "@ui5/webcomponents-icons/dist/refresh.js";
import "@ui5/webcomponents-icons/dist/simulate.js";
import "@ui5/webcomponents-icons/dist/sort.js";
import "@ui5/webcomponents-icons/dist/delete.js";
import "@ui5/webcomponents-icons/dist/cart-4.js";
import "@ui5/webcomponents-icons/dist/map-fill.js";
import "@ui5/webcomponents-icons/dist/map.js";
import "@ui5/webcomponents-icons/dist/lateness.js";
import "@ui5/webcomponents-icons/dist/future.js";
import "./OrderManagerView.css";
import ModalWindowV2 from "../../../base/modal/ModalWindowV2";
import usePVZSelector from "./PVZSelector";
import usePvzSearcher from "../../../../hooks/PvzSearcher";
import getLog from "../../../../requests/utils";
import Log from "../../../base/log/Log";
import { Modal } from "@mantine/core";
import { isAdminAtom } from "../../../../hoc/GlobalAtoms";
import { useAtom } from "jotai";
import OrderManagerStepInput from "./OrderManagerStepInput";

const fields = {
  delivery_type_choose: {
    key: "delivery_type_choose",
    header: "Выбор",
    editable: false,
    filterable: false,
  },
  delivery_type: {
    key: "delivery_type",
    header: "Вариант доставки",
    editable: false,
  },
  delivery_period: {
    key: "delivery_period",
    header: "Срок доставки, дн",
    editable: false,
    align: "center",
    Cell: ({ cell, renderedCellValue }) => (
      <Box
        sx={{
          textAlign: "center",
        }}
      >
        {renderedCellValue}
      </Box>
    ),
  },
  price: {
    key: "price",
    header: "Цена, руб",
    editable: false,
    align: "center",
  },
  price_with_delivery_price: {
    key: "price_with_delivery_price",
    header: "Цена с доставкой, руб",
    editable: false,
    align: "center",
  },
  order_price: {
    key: "order_price",
    header: "Стоимость заказа, руб",
    editable: false,
    align: "center",
  },
  needs_confirmed: {
    key: "needs_confirmed",
    header: "Подтверждено/Потребность, шт",
    editable: false,
    align: "center",
  },
};

const admin_fields = {
  delivery_type_choose: {
    key: "delivery_type_choose",
    header: "Выбор",
    editable: false,
    filterable: false,
  },
  delivery_type: {
    key: "delivery_type",
    header: "Вариант доставки",
    editable: false,
  },
  delivery_period: {
    key: "delivery_period",
    header: "Срок доставки, дн",
    editable: false,
    align: "center",
    Cell: ({ cell, renderedCellValue }) => (
      <Box
        sx={{
          textAlign: "center",
        }}
      >
        {renderedCellValue}
      </Box>
    ),
  },
  price: {
    key: "price",
    header: "Цена, руб",
    editable: false,
    align: "center",
  },
  delivery_cost: {
    key: "delivery_cost",
    header: "Стоимость доставки, руб",
    editable: false,
    align: "center",
  },
  price_with_delivery_price: {
    key: "price_with_delivery_price",
    header: "Цена с доставкой, руб",
    editable: false,
    align: "center",
  },
  end_price_with_delivery_price: {
    key: "end_price_with_delivery_price",
    header: "Итоговая цена с доставкой, руб",
    editable: false,
    align: "center",
  },
  order_price: {
    key: "order_price",
    header: "Стоимость заказа, руб",
    editable: false,
    align: "center",
  },
  needs_confirmed: {
    key: "needs_confirmed",
    header: "Подтверждено/Потребность, шт",
    editable: false,
    align: "center",
  },
  supplier_warehouse: {
    key: "supplier_warehouse",
    header: "Склад поставщика",
    editable: false,
  },
};

const formed_fields = {
  delivery_type_choose: {
    key: "delivery_type_choose",
    header: "Выбор",
    editable: false,
    filterable: false,
  },
  delivery_type: {
    key: "delivery_type",
    header: "Вариант доставки",
    editable: false,
  },
  delivery_period: {
    key: "delivery_period",
    header: "Срок доставки, дн",
    editable: false,
    align: "center",
    Cell: ({ cell, renderedCellValue }) => (
      <Box
        sx={{
          textAlign: "center",
        }}
      >
        {renderedCellValue}
      </Box>
    ),
  },
  price: {
    key: "price",
    header: "Цена, руб",
    editable: false,
    align: "center",
  },
  end_price_with_delivery_price: {
    key: "end_price_with_delivery_price",
    header: "Итоговая цена с доставкой, руб",
    editable: false,
    align: "center",
  },
  order_price: {
    key: "order_price",
    header: "Стоимость заказа, руб",
    editable: false,
    align: "center",
  },
  needs_confirmed: {
    key: "needs_confirmed",
    header: "Подтверждено/Потребность, шт",
    editable: false,
    align: "center",
  },
};

const OrderManagerView = (props) => {
  const [isAdmin] = useAtom(isAdminAtom);
  const globalTheme = useTheme({
    palette: {
      primary: lime,
      secondary: purple,
    },
  });
  const { searcher, value, setValue } = usePVZSelector(props.order?.id);
  const [orderData, setOrderData] = useState([]);
  const [orderManager, setOrderManager] = useState({});
  const [orderManagerDeliveryData, setOrderManagerDeliveryData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [menuOpen, setMenOpen] = useState(false);
  const [inputCount, setInputCount] = useState(0);
  const [orderCount, setOrderCount] = useState(0);
  const [reason, setReason] = useState("Некорректный заказ");
  const [isPVZ, setIsPVZ] = useState(false);
  const [deleteOrderManger, setDeleteOrderManager] = useState(false);
  const [orderManagerIsDelivered, setOrderManagerIsDelivered] = useState(false);
  const { pvzSearcher, pvzValue, pvzSetValue, geoObjectSet, aggregatorSet } =
    usePvzSearcher(
      {},
      "",
      orderManager?.chosen_variant?.aggregator,
      orderManager?.endpoint?.geo_object?.id
    );

  const getOrderData = async (loading) => {
    if (props.order?.id != null) {
      const order_data = [];
      loading && setLoading(true);
      await axios_instance
        .get(`orders/order_manager/get/by_id/${props.order?.id}`)
        .then(({ data }) => {
          if (loading) {
            data.manager_dict.map((manager) => {
              const sub_rows = [];
              manager.subRows.map((subRow) => {
                sub_rows.push({
                  id: subRow.id,
                  price: subRow.price,
                  delivery_cost: subRow.delivery_cost,
                  delivery_period: `${subRow.delivery_min_period} - ${subRow.delivery_max_period} дн.`,
                  price_with_delivery_price: subRow.price_with_delivery_price,
                  order_price: subRow.order_price,
                  end_price_with_delivery_price:
                    isAdmin &
                    props.projectFormed &
                    (data?.manager?.requirement_processed != null) ? (
                      <OrderManagerStepInput
                        is_selected={
                          manager.delivery_type ===
                          data?.manager?.chosen_variant?.type?.name
                        }
                        order_item_id={subRow.id}
                        delivery_cost={subRow.delivery_cost}
                        price_with_delivery_price={subRow.total_cost ? subRow.total_cost : subRow.price_with_delivery_price}
                        getOrderData={getOrderData}
                      />
                    ) : (
                      subRow.price_with_delivery_price
                    ),
                  needs_confirmed: subRow.needs_confirmed,
                  supplier_warehouse: subRow.supplier_warehouse,
                  total_cost: subRow.total_cost,
                  is_selected: manager.delivery_type === data?.manager?.chosen_variant?.type?.name ? true : false,
                });
              });
              order_data.push({
                id: manager.id,
                delivery_type: manager.delivery_type,
                needs_confirmed: `${data?.manager?.status.confirmed_positions_count}/${data?.manager?.status.positions_count}`,
                order_price: manager.order_price,
                delivery_period: `${manager.delivery_min_period} - ${manager.delivery_max_period} дн.`,
                subRows: sub_rows,
              });
            });
            setOrderManager(data.manager);
            setInputCount(data.manager?.count);
            setOrderCount(data.manager?.count);
            geoObjectSet(data?.manager?.endpoint?.geo_object);
            aggregatorSet(data?.manager?.chosen_variant?.aggregator);
            getDeliveryData();
            setIsPVZ(data?.manager?.is_pvz_delivery);
          } else {
            data.manager_dict.map((manager) => {
              const sub_rows = [];
              manager.subRows.map((subRow) => {
                sub_rows.push({
                  id: subRow.id,
                  price: subRow.price,
                  delivery_cost: subRow.delivery_cost,
                  delivery_period: `${subRow.delivery_min_period} - ${subRow.delivery_max_period} дн.`,
                  price_with_delivery_price: subRow.price_with_delivery_price,
                  order_price: subRow.order_price,
                  end_price_with_delivery_price:
                    isAdmin &
                    props.projectFormed &
                    (data?.manager?.requirement_processed != null) ? (
                      <OrderManagerStepInput
                        is_selected={
                          manager.delivery_type ===
                          data?.manager?.chosen_variant?.type?.name
                        }
                        order_item_id={subRow.id}
                        delivery_cost={subRow.delivery_cost}
                        price_with_delivery_price={subRow.total_cost ? subRow.total_cost : subRow.price_with_delivery_price}
                        getOrderData={getOrderData}
                      />
                    ) : (
                      subRow.price_with_delivery_price
                    ),
                  needs_confirmed: subRow.needs_confirmed,
                  supplier_warehouse: subRow.supplier_warehouse,
                  total_cost: subRow.total_cost,
                  is_selected: manager.delivery_type === data?.manager?.chosen_variant?.type?.name ? true : false,
                });
              });
              order_data.push({
                id: manager.id,
                delivery_type: manager.delivery_type,
                needs_confirmed: `${data?.manager?.status.confirmed_positions_count}/${data?.manager?.status.positions_count}`,
                order_price: manager.order_price,
                delivery_period: `${manager.delivery_min_period} - ${manager.delivery_max_period} дн.`,
                subRows: sub_rows,
              });
            });
            setOrderManager(data.manager);
            geoObjectSet(data?.manager?.endpoint?.geo_object);
            aggregatorSet(data?.manager?.chosen_variant?.aggregator);
            getDeliveryData();
          }
          loading && setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
      setOrderData(order_data);
    }
  };

  useEffect(() => {
    const interval = setInterval(async () => {
      if (props.order?.id !== null) {
        await getOrderData(false);
      }
    }, 5000);
    return () => clearInterval(interval);
  }, [props.order?.id]);

  const getDeliveryData = async () => {
    await axios_instance
      .get(`delivery/order/get/by_directions/${props.order?.id}`)
      .then(({ data }) => {
        setOrderManagerDeliveryData(data);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const setOrderManagerPVZ = async () => {
    await axios_instance
      .put(`/orders/order_manager/set/pvz/${orderManager?.id}`, {
        pvz: pvzValue?.id,
      })
      .then(({ data }) => {
        getOrderData(true);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const getName = (product) => {
    var name = "";
    product?.properties?.map((property) => {
      if (property.name === "Наименование") {
        name = property?.value?.content || "Не указан";
      }
    });
    return name;
  };

  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode, //let's use the same dark/light mode as the global theme
          primary: grey, //swap in the secondary color as the primary for the table
          info: {
            main: "#e1f4ff", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "#fff" //random light yellow color for the background in light mode
                : "#000", //pure black table in dark mode for fun
          },
          sx: {
            "&:hover": {
              backgroundColor: "#e1f4ff", // Replace this with your desired hover color
            },
            textAlign: "center",
          },
        },
        typography: {
          button: {
            textTransform: "none", //customize typography styles for all buttons in table by default
            fontSize: "1.2rem",
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "1.1rem", //override to make tooltip font size larger
              },
            },
          },
          MuiSwitch: {
            styleOverrides: {
              thumb: {
                color: "gray", //change the color of the switch thumb in the columns show/hide menu to pink
              },
            },
          },
        },
      }),
    [globalTheme]
  );

  const data_columns = [];
  const column_fields = isAdmin
    ? admin_fields
    : props.projectFormed && orderManager?.requirement_processed
    ? formed_fields
    : fields;
  for (let main_key in column_fields) {
    data_columns.push({
      accessorKey: column_fields[main_key].key, //simple recommended way to define a column
      header: column_fields[main_key].header,
      muiTableHeadCellProps: {
        sx: {
          color: "black",
          textAlign: "center",
        },
      },
      size: 300,
      muiTableBodyRowProps: {
        onHover(e, value) {
          e.target.style.background = "#e1f4ff";
        },
        sx: {
          textAlign: "center",
        },
      },
      sx: {
        "&:hover": {
          backgroundColor: "#e1f4ff", // Replace this with your desired hover color
        },
        textAlign: "center",
      },
      enableEditing: column_fields[main_key].editable,
      muiEditTextFieldProps: ({ cell, row }) => ({
        type: "number",
      }),
      muiTopToolbarProps: {
        background: "#e1f4ff",
      },
      onColumnFilterFnsChange: column_fields[main_key].onColumnFilterFnsChange,
      enableColumnFilter: column_fields[main_key].filterable && true,
      Cell:
        main_key === "delivery_type_choose"
          ? ({ renderedCellValue, row }) =>
              row.getCanExpand() ? (
                <Button
                  disabled={
                    row.original?.delivery_type ===
                      orderManager?.chosen_variant?.type?.name ||
                    props.orderFormed
                  }
                  onClick={() => {
                    chooseOrderVariantHandler(row);
                  }}
                >
                  Выбрать
                </Button>
              ) : (
                <></>
              )
          : null,
    });
  }

  const removeFromProjectOrderManager = async (id) => {
    await axios_instance
      .delete("/orders/order_manager/remove/" + id)
      .then(({ data }) => {
        props.getProjectByID(true);
      })
      .catch((error) => {});
  };

  const fcsCalculateOnEdit = async (id, count) => {
    if (count > 0) {
      await axios_instance
        .post(`/fcs/calculate/stock/on_edit/` + id, {
          count: count,
          is_pvz: isPVZ,
        })
        .then(({ data }) => {
          getOrderData(true);
          props.getProjectByID(true);
        })
        .catch((error) => {});
    }
  };

  const chooseOrderVariant = async (order_manager_id, chosen_variant) => {
    await axios_instance
      .put(`/orders/order_manager/choose/variant/${order_manager_id}`, {
        chosen_variant: chosen_variant,
      })
      .then(({ data }) => {
        getOrderData(true);
        props.getProjectByID();
      })
      .catch((error) => {});
  };

  const chooseOrderVariantHandler = (row) => {
    chooseOrderVariant(orderManager?.id, row.original?.id);
  };

  const cancelDeliveryOrderHandler = async () => {
    await axios_instance
      .post(`/delivery/order/cancel/${props.order?.id}`, {
        reason: reason,
      })
      .then(({ data }) => {
        getOrderData(true);
      })
      .catch((error) => {});
  };

  const orderManagerHasDelivered = async () => {
    await axios_instance
      .put(`/orders/order_manager/set/has_delivered/${props.order?.id}`)
      .then(({ data }) => {
        setOrderManagerIsDelivered(true);
        props.getProjectByID();
        // props.checkOrderManagersHasDelivered()
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getOrderData(true);
  }, []);

  return (
    <>
      <ObjectPageSubSection
        titleText="Инфорация о заказе"
        actions={
          <>
            {" "}
            {props.orderProjectData?.delivered ? (
              <Button
                disabled={!!props.order?.has_delivered}
                className={props.order?.has_delivered ? "isDelivered" : ""}
                onClick={() => {
                  orderManagerHasDelivered();
                }}
              >
                {props.order?.has_delivered
                  ? "Доставка подтверждена"
                  : "Подтвердить доставку"}
              </Button>
            ) : (
              <></>
            )}
            <Button
              icon="paging"
              id={`openOrderMenuBtn${orderManager?.id}`}
              onClick={() => setMenOpen(!menuOpen)}
            ></Button>
            <Menu
              opener={`openOrderMenuBtn${orderManager?.id}`}
              open={menuOpen}
              onAfterClose={() => {
                setMenOpen(false);
              }}
              onItemClick={(e) => {
                switch (e.detail.item.id) {
                  case "delete":
                    setDeleteOrderManager(true);
                    return;
                  case "replace":
                    props.setChoosedOrderItemToReplace(props.order?.id);
                    props.setOpenReplaceOrderItemModal();
                    return;
                  case "recalculate":
                    fcsCalculateOnEdit(props.order?.id, inputCount);
                    return;
                  case "reload":
                    getOrderData(true);
                    return;
                }
              }}
            >
              <MenuItem
                disabled={props.orderFormed}
                id="delete"
                icon="delete"
                text="Удалить из проекта заказа"
              />
              <MenuItem
                disabled={props.orderFormed}
                id="replace"
                icon="sort"
                text="Переместить в другой проект заказа"
              />
              <MenuItem
                disabled={props.orderFormed}
                id="recalculate"
                icon="simulate"
                text="Пересчитать"
              />
              <MenuItem id="reload" icon="refresh" text="Обновить" />
            </Menu>
          </>
        }
      >
        {deleteOrderManger ? (
          <FlexBox
            style={{
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            <Label style={{ fontSize: "1rem" }}>
              Вы уверены, что хотите удалить данную позицию из проекта заказа?
            </Label>
            <FlexBox
              style={{
                gap: "1rem",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                onClick={() => removeFromProjectOrderManager(props.order?.id)}
              >
                Да
              </Button>
              <Button onClick={() => setDeleteOrderManager(false)}>Нет</Button>
            </FlexBox>
          </FlexBox>
        ) : (
          <>
            {loading ? (
              <BusyIndicator active />
            ) : (
              <FlexBox
                alignItems="Stretch"
                direction="Column"
                justifyContent="Start"
                wrap="NoWrap"
                style={{
                  padding: "1rem 0",
                  backgroundColor: "#fff",
                  borderRadius: "10px",
                  gap: "1rem",
                }}
              >
                <Form
                  backgroundDesign="Transparent"
                  columnsL={1}
                  columnsM={1}
                  columnsS={1}
                  columnsXL={2}
                  labelSpanL={4}
                  labelSpanM={2}
                  labelSpanS={12}
                  labelSpanXL={4}
                  style={{
                    alignItems: "start",
                    borderRadius: "1rem",
                    width: "100%",
                  }}
                >
                  <FormItem
                    style={{
                      fontSize: "5rem",
                    }}
                    label="Артикул"
                  >
                    <Text>{orderManager?.product?.code}</Text>
                  </FormItem>
                  <FormItem label="Полное наименование товара в каталоге">
                    <Text>{orderManager?.product?.name}</Text>
                  </FormItem>
                  <FormItem label="Бренд">
                    <Text>
                      {orderManager?.product?.brand?.name || "Не указан"}
                    </Text>
                  </FormItem>
                  <FormItem label="Производитель">
                    <Text>
                      {orderManager?.product?.manufacture?.name || "Не указан"}
                    </Text>
                  </FormItem>
                  <FormItem label="Склад">
                    <Text>{orderManager?.endpoint?.name}</Text>
                  </FormItem>

                  <FormItem label="Выбранный вариант доставки">
                    <Badge
                      colorScheme={orderManager?.chosen_variant ? "6" : "10"}
                    >
                      {orderManager?.chosen_variant
                        ? orderManager?.chosen_variant?.type?.name
                        : "Не выбрана"}
                    </Badge>
                  </FormItem>

                  <FormItem label={"Статус расчета"}>
                    {/* <Badge
                  colorScheme={orderManager?.requirement_processed ? "8" : "1"}
                > */}
                    <Log
                      uuid={props?.order?.id}
                      type={"no_stocks_to_sale"}
                      condition={orderManager?.requirement_processed}
                      conditionValue={
                        orderManager?.requirement_processed
                          ? "Обработан"
                          : "В обработке(перезагрузите потребность для получения информации)"
                      }
                      colorScheme={
                        orderManager?.requirement_processed ? "8" : "1"
                      }
                    ></Log>
                    {/* </Badge> */}
                  </FormItem>

                  <FormItem label={"Статус заказа"}>
                    <Badge
                      colorScheme={
                        props.orderFormed
                          ? orderManager?.status?.code == "OK" &&
                            orderManager?.status?.order_manager_confirmed
                            ? "8"
                            : "1"
                          : "10"
                      }
                    >
                      {orderManager?.status?.code == "OK" && props.orderFormed
                        ? `Подтверждено ${orderManager?.status?.confirmed_positions_count} / ${orderManager?.status?.positions_count}`
                        : "Не оформлен"}
                    </Badge>
                  </FormItem>

                  <FormItem label="Необходимое количество">
                    <StepInput
                      disabled={props.orderFormed}
                      valuePrecision={2}
                      value={inputCount}
                      min={0}
                      style={{
                        width: "50%",
                      }}
                      onChange={(e) => {
                        setInputCount(e.target.value);
                      }}
                      onInput={(e) => {
                        setInputCount(e.target.value);
                      }}
                    />
                  </FormItem>

                  <FormItem label={"Доставка в ПВЗ"}>
                    <Switch
                      design="Textual"
                      name="ПВЗ/Дверь"
                      onChange={() => setIsPVZ(!isPVZ)}
                      checked={isPVZ}
                      style={{
                        width: "300px",
                      }}
                      textOff="Нет"
                      textOn="Да"
                    />
                  </FormItem>
                  {orderManager?.chosen_variant &&
                  orderManager.is_pvz_delivery &&
                  !orderManager.chosen_pvz ? (
                    <FormItem label={"Выбрать ПВЗ"}>
                      <FlexBox direction="Column" wrap="NoWrap">
                        {pvzSearcher}
                        <Button onClick={() => setOrderManagerPVZ()}>
                          Выбрать ПВЗ
                        </Button>
                      </FlexBox>
                    </FormItem>
                  ) : (
                    <FormItem label={"Выбранный ПВЗ"}>
                      <FlexBox direction="Column" wrap="NoWrap">
                        <Text>
                          {orderManager?.chosen_pvz?.pvz?.name || "Не указан"}
                        </Text>
                        <Text>
                          {orderManager?.chosen_pvz?.exact_address ||
                            "Не указан"}
                        </Text>
                      </FlexBox>
                    </FormItem>
                  )}
                </Form>
                {orderCount !== inputCount ? (
                  <MessageStrip hideCloseButton>
                    Количество изменено, необходимо пересчитать заказ
                  </MessageStrip>
                ) : (
                  <></>
                )}
                {inputCount <= 0 ? (
                  <MessageStrip design="Negative" hideCloseButton>
                    Нельзя указать необходимое количество меньшее или равное 0
                  </MessageStrip>
                ) : (
                  <></>
                )}
                <Panel
                  style={{
                    marginTop: "10px",
                  }}
                  accessibleRole="Form"
                  headerLevel="H2"
                  headerText="Журнал доставки"
                  collapsed={true}
                >
                  {(orderManagerDeliveryData?.length !== 0) &
                  (orderManagerDeliveryData !== null) &
                  (orderManagerDeliveryData !== undefined) ? (
                    orderManagerDeliveryData?.map((order) => (
                      <>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <Label>
                            Номер заказа:{" "}
                            {order?.delivery_order_id?.delivery_order_id}
                          </Label>
                          <Label>
                            {!order?.delivery_date
                              ? `Ожидаемая дата доставки: ${order?.planned_delivery_date}`
                              : `Доставка завершена ${order?.delivery_date}`}
                          </Label>
                        </div>

                        <Timeline
                          layout="Horizontal"
                          style={{
                            "::before": {
                              backgroundColor: "#ff0000",
                            },
                          }}
                        >
                          {order?.statuses?.map((status) => (
                            <TimelineItem
                              className={
                                order?.statuses?.indexOf(status) + 1 ===
                                  order?.statuses?.length &&
                                !order?.delivery_date
                                  ? "tracking-timeline-item-working"
                                  : "tracking-timeline-item-success"
                              }
                              icon={"map"}
                              name={""}
                              subtitleText={`Дата статуса: ${status?.status_date}`}
                              titleText={`${status?.status} город: ${
                                status?.address
                                  ? status.address.name
                                  : "Не указан"
                              }`}
                            ></TimelineItem>
                          ))}
                          {!order?.delivery_date && (
                            <>
                              <TimelineItem
                                icon="map"
                                name={" "}
                                subtitleText=" "
                                titleText="..."
                              ></TimelineItem>
                              <TimelineItem
                                icon="map"
                                name={" "}
                                subtitleText=" "
                                titleText={`Ожидаемая дата доставки ${order?.planned_delivery_date}`}
                              ></TimelineItem>
                            </>
                          )}
                          {order?.delivery_order_id?.refusal && (
                            <TimelineItem
                              icon="decline"
                              name={" "}
                              subtitleText=" "
                              titleText="Доставка отменена"
                            ></TimelineItem>
                          )}
                        </Timeline>
                        {
                          <>
                            {!order?.delivery_order_id?.refusal &&
                            !order?.delivery_date ? (
                              <div
                                style={{
                                  gap: "1rem",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Button
                                  disabled={
                                    order?.statuses.filter(
                                      (status) =>
                                        status.status === "Назначен курьер"
                                    ).length > 0
                                  }
                                  onClick={() => {
                                    cancelDeliveryOrderHandler();
                                  }}
                                >
                                  Отменить заказ на доставку
                                </Button>
                                <Input
                                  placeholder="Причина отказа"
                                  onChange={(e) => setReason(e.target.value)}
                                >
                                  Причина отмены заказа на доставку
                                </Input>
                              </div>
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  gap: "1rem",
                                  alignItems: "center",
                                }}
                              >
                                <Button onClick={() => {}}>
                                  Подтвердить доставку
                                </Button>
                                <div
                                  style={{
                                    display: "flex",
                                    gap: "1rem",
                                    alignItems: "center",
                                  }}
                                >
                                  <Button onClick={() => {}}>
                                    Оформить возврат
                                  </Button>
                                  <Input
                                    placeholder="Причина возврата"
                                    onChange={(e) => {}}
                                  >
                                    Причина возврата заказа
                                  </Input>
                                </div>
                              </div>
                            )}
                          </>
                        }
                      </>
                    ))
                  ) : (
                    <Badge colorScheme="1">
                      Для просмотра истории заказа на доставку необходимо
                      оформить заказ на доставку
                    </Badge>
                  )}
                </Panel>
                <TableContainer component={Paper}>
                  <MuiThemeProvider theme={tableTheme}>
                    <MaterialReactTable
                      columns={data_columns}
                      data={orderData}
                      localization={MRT_Localization_RU}
                      defaultColumn={{
                        // maxSize: 150,
                        minSize: 100,
                        size: 100, //default size is usually 180
                      }}
                      sx={{
                        fontSize: 36,
                        innerHeight: "100%",
                      }}
                      enableExpanding
                      editDisplayMode="cell"
                      enableEditing={false}
                      enableColumnResizing
                      enablePagination={false}
                      enableColumnFilters={true}
                      enableColumnOrdering={true}
                      enableGrouping={false}
                      enableColumnPinning={false}
                      enableFacetedValues={false}
                      muiTableBodyCellProps={({ row, cell }) => ({
                        sx: {
                          backgroundColor:
                              (!row.original.total_cost) && (row.original.is_selected) &&
                            "#f2a4a4" ||
                              (row.original.total_cost) && (row.original.is_selected) &&
                            "#d5fcd7",
                          borderTop:
                            (!row.original.total_cost) && (row.original.is_selected) &&
                            "1px solid #ed6f6f" ||
                              (row.original.total_cost) && (row.original.is_selected) &&
                            "1px solid #5ae361",
                          borderBottom:
                            (!row.original.total_cost) && (row.original.is_selected) &&
                            "1px solid #ed6f6f" ||
                              (row.original.total_cost) && (row.original.is_selected) &&
                            "1px solid #5ae361",
                          textAlign: "center",
                          alignContent: "center",
                          alignItems: "center",
                        },
                      })}
                      state={{
                        isLoading: loading,
                      }}
                      initialState={{
                        density: "compact",
                      }}
                      renderTopToolbar={({ table }) => {
                        return <></>;
                      }}
                      renderBottomToolbar={({ table }) => {
                        return <></>;
                      }}
                    />
                  </MuiThemeProvider>
                </TableContainer>
              </FlexBox>
            )}
          </>
        )}
      </ObjectPageSubSection>
    </>
  );
};

export default OrderManagerView;
