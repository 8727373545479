import axios_instance from "../../../requests/config_defaults"
import React, { useEffect, useState } from "react"
import { Modal, Loader } from "@mantine/core"
import {
  Button,
  Input,
  IllustratedMessage,
  ObjectPage,
  Link,
  FlexBox,
  DynamicPageHeader,
  Label,
  DynamicPageTitle,
  ObjectStatus,
  ObjectPageSection,
  Badge,
  MessageStrip,
  ActionSheet,
  ButtonDesign,
  CheckBox,
  Timeline,
  TimelineItem,
  Panel,
  Popover,
  Card,
  CardHeader,
  Select,
  Option,
  Icon,
} from "@ui5/webcomponents-react";
import "./OrderProjectInfoSide.css";
import "react-toastify/dist/ReactToastify.css";
import Paginator from "../../base/paginator/Paginator";
import OrderItemAddToOrderProjectView from "./OrderItemAddToOrderProjectView/OrderItemAddToOrderProjectView";
import OrderManagerView from "./order-manager-view/OrderManagerView";
import "@ui5/webcomponents-icons/dist/refresh.js";
import "@ui5/webcomponents-icons/dist/decline.js";
import "@ui5/webcomponents-icons/dist/activity-2.js";
import "@ui5/webcomponents-icons/dist/decision.js";
import "@ui5/webcomponents-icons/dist/cart-4.js";
import "@ui5/webcomponents-icons/dist/complete.js";
import "@ui5/webcomponents-icons/dist/cart-approval.js";
import "@ui5/webcomponents-icons/dist/inventory.js";
import "@ui5/webcomponents-icons/dist/monitor-payments.js";
import { useAtom } from "jotai";
import { activeCompanyAtom, isAdminAtom } from "../../../hoc/GlobalAtoms";
import { createPortal } from "react-dom";
import ContactSelector from "./order-manager-view/ContactSelector";
import axios from "axios";
import { json } from "react-router-dom";

const OrderProjectInfoSide = (props) => {
  const [columnFilters, setColumnFilters] = useState([])
  const [orderProjectData, setOrderProjectData] = useState(null)
  const [openModal, setOpenModal] = useState(false)
  const [openReplaceOrderItemModal, setOpenReplaceOrderItemModal] =
    useState(false)
  const [orderProjects, setOrderProjects] = useState([])
  const [
    openModalReplaceOrderItemLoading,
    setOpenModalReplaceOrderItemLoading,
  ] = useState(false)
  const [pageCount, setPageCount] = useState(1)
  const [page, setPage] = useState(1)
  const [choosedOrderItemToReplace, setChoosedOrderItemToReplace] = useState("")
  const [checkOnEditingFCSValue, setCheckOnEditingFCSValue] = useState(false)
  const [loading, setLoading] = useState(false)
  const [availableToForm, setAvailableToForm] = useState(false)
  const [orderFormed, setOrderFormed] = useState(false)
  const [selectedSectionId, setSelectedSectionId] = useState("")
  const [managersStatusesPopover, setManagersStatusesPopover] = useState(false)
  const [managersStatusesList, setManagersStatusesList] = useState(false)

  const [companyContacts, setCompanyContacts] = useState([])
  const [contact, setContact] = useState("")

  const [isAdmin] = useAtom(isAdminAtom)

  const [activeCompany] = useAtom(activeCompanyAtom)
  console.log(activeCompany)
  const [deliveryError, setDeliveryError] = useState(false);
  const [orderDelivered, setOrderDelivered] = useState(false);
  const [messageStripOpen, setMessageStripOpen] = useState(true);
  const [headerObjectPageStripOpen, setHeaderObjectPageStripOpen] = useState(true);
  const [projectFormed, setProjectFormed] = useState(false);
  const [priceDownloaded, setPriceDownloaded] = useState(false);

  const getOrdersStatuses = (managers) => {
    const statuses = []
    managers?.map((manager) =>
      statuses.push({
        id: manager?.id,
        product_code: manager?.product?.code,
        product_brand: manager?.product?.brand?.name || "Не указан",
        product_manufactor: manager?.product?.manufactor?.name || "Не указан",
        product_name: manager?.product?.name || "Не указан",
        code: manager?.status?.code,
        positions_count: manager?.status?.positions_count,
        confirmed_positions_count: manager?.status?.confirmed_positions_count,
        refused_positions_count: manager?.status?.refused_positions_count,
        order_manager_confirmed: manager?.status?.order_manager_confirmed,
      })
    )
    return statuses
  }

  const getProjectByID = async () => {
    if (props.id) {
      setLoading(true)
      setOrderProjectData(null)
      await axios_instance
        .get("/orders/order_project/managers/get/by_id/" + props.id)    
        .then(({ data }) => {
          setPriceDownloaded(data.all_total_cost_filled)
          setOrderProjectData(data && data);
          setAvailableToForm(
            (prev) => data?.available_to_form && data?.managers.length > 0
          )
          setOrderFormed(data?.formed !== null || undefined)
          setManagersStatusesList(getOrdersStatuses(data?.managers))
          setLoading(false)
          setProjectFormed(
            data?.formed &&
            !data?.completed &&
            !data?.payment &&
            !data?.delivered ? true : false)
        })
        .catch((error) => {
          console.log(error)
          setLoading(false)
        })
    }
  }

  const filterOrderProjects = async () => {
    setOpenModalReplaceOrderItemLoading(true)
    await axios_instance
      .post(
        `/orders/order_project/filter?page=${page}&filters=${JSON.stringify(
          columnFilters
        )}`,
        columnFilters
      )
      .then(({ data }) => {
        setPageCount(
          data?.count % 20 === 0
            ? data?.count / 20
            : Math.floor(data?.count / 20) + 1
        )
        setOrderProjects(data.results)
        setOpenModalReplaceOrderItemLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setOpenModalReplaceOrderItemLoading(false)
      })
  }

  useEffect(() => {
    getProjectByID()
    getContacts()
  }, [props.id])

  // useEffect(() => {
  //   setOrderProjectData();
  // }, [props.id]);

  const addButtonHandler = () => {
    setOpenModal(true)
  }

  const replaceOrderItemToProject = async (order_project_id, delivery_type) => {
    await axios_instance
      .put(
        `/orders/order_manager/replace/${choosedOrderItemToReplace}/${order_project_id}`,
        { type: delivery_type }
      )
      .then(({ data }) => {
        getProjectByID()
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const chooseOrderVariant = async (order_manager_id, delivery_type) => {
    await axios_instance
      .put(`/orders/order_manager/choose/variant/${order_manager_id}`, {
        delivery_type: delivery_type,
      })
      .then(({ data }) => {
        getProjectByID()
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const formOrderProject = async () => {
    await axios_instance
      .put(`/orders/order_project/form/${props.id}`)
      .then(({ data }) => {
        getProjectByID()
      })
      .catch((error) => {
        getProjectByID()
      })
  }

  const payConfirmForOrderProject = async () => {
    await axios_instance
      .put(`/orders/order_project/pay/confirm/${props.id}`)
      .then(({ data }) => {
        getProjectByID()
      })
      .catch((error) => {
        getProjectByID()
      })
  }

  const deliveryForOrderProject = async () => {
    console.log(contact)
    if (!contact) {
      console.log("No contact")
      setDeliveryError(true)
    } else {
      await axios_instance
        .post(`/orders/order_project/delivery/${props.id}`, {
          contact: contact,
        })
        .then(({ data }) => {
          getProjectByID()
          setDeliveryError(false)
        })
        .catch((error) => {
          getProjectByID()
          setDeliveryError(false)
        })
    }
  }

  const generateInvoice = async () => {
    await axios_instance
      .post(`/orders/order_project/invoice/generate/${props.id}`)
      .then(({ data }) => {
        let child = window.open("Invoice", "_blank")
        child.document.write(`${data?.detail}`)
        child.print()
        child.document.close()
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const getFile = async () => {
    //   await axios({
    //     url: 'http://127.0.0.1:10303/document/resource/active/get/by_document_id/' + "5c4a1804-81ee-4cee-8682-e2f77b03dd46", //your url
    //     method: 'GET',
    //     responseType: 'blob', // important
    //     withCredentials: true
    // }).then((response) => {
    //     // create file link in browser's memory
    //     const href = URL.createObjectURL(response.data);

    //     // create "a" HTML element with href to file & click
    //     const link = document.createElement('a');
    //     link.href = href;
    //     link.setAttribute('download', 'file.pdf'); //or any other extension
    //     document.body.appendChild(link);
    //     link.click();

    //     // clean up "a" element & remove ObjectURL
    //     document.body.removeChild(link);
    //     URL.revokeObjectURL(href);
    // });
    if (orderProjectData?.document) {
      await axios({
        url:
          "https://exza.psg.su/proxy/document/resource/active/get/by_document_id/" +
          orderProjectData?.document, //your url
        method: "GET",
        responseType: "blob", // important
        withCredentials: true,
      })
        .then((response) => {
          // create file link in browser's memory
          const href = URL.createObjectURL(response.data)

          // create "a" HTML element with href to file & click
          const link = document.createElement("a")
          link.href = href
          link.setAttribute("download", "Счет_на_оплату.pdf") //or any other extension
          document.body.appendChild(link)
          link.click()

          // clean up "a" element & remove ObjectURL
          document.body.removeChild(link)
          URL.revokeObjectURL(href)
        })
        .catch((error) => {
          console.log(error)
          generateInvoice()
        })
    }
  }

  const openModalReplaceOrderItemHandler = () => {
    setOpenReplaceOrderItemModal(true)
    filterOrderProjects()
  }
  const onClickBtnReplaceOrderItemHandler = (id) => {
    replaceOrderItemToProject(id)
    setOpenReplaceOrderItemModal(false)
    getProjectByID()
  }

  const handleSaveCell = (cell, value) => {
    const patch_data = {}
    patch_data[cell.column.id] = parseFloat(value)
    orderProjectData[cell.row.index][cell.column.id] = value
    setOrderProjectData([...orderProjectData])
  }

  const chooseOrderVariantHandler = (row) => {
    chooseOrderVariant(
      orderProjectData[parseInt(row.parentId)].id,
      row.original?.delivery_type
    )
    console.log(orderProjectData[parseInt(row.parentId)].id)
  }

  const handleCloseAddOrderItemModal = () => {
    setOpenModal(false)
    getProjectByID()
  }

  const getName = (product) => {
    var name = ""
    product?.properties?.map((property) => {
      if (property.name === "Наименование") {
        name = property?.value?.content || "Не указан"
      }
    })
    return name
  }

  const getContacts = async () => {
    await axios_instance
      .get(`/myCompany`)
      .then(({ data }) => {
        setCompanyContacts(data?.contacts)
        console.log(companyContacts)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  useEffect(() => {
    props.setOpenProject(false)
  }, [activeCompany])

  const setContactInfo = (event) => {
    setContact(event.detail.selectedOption.id)
    setDeliveryError(false)
  }
  // const handleCheckOrderManagersHasDelivered = () => {
  //   const allDelivered = orderProjectData?.managers?.every(manager => manager.has_delivered)
  //   if (allDelivered) {
  //     setOrderDelivered(true)
  //   }
  // }
  const formatTimeToLocal = (time) => {
    // Пример времени из базы данных в формате UTC
    // Разбиение строки на составляющие
    const [datePart, timePart] = time.split(' ');
    // Разбиение даты на год, месяц и день
    const [day, month, year] = datePart.split('.');
    // Разбиение времени на часы, минуты и секунды
    const [hours, minutes, seconds] = timePart.split(':');
    // Создание объекта Date в формате UTC
    const processedTimeUTC = new Date(Date.UTC(year, month - 1, day, hours, minutes, seconds));
    // Преобразование времени из UTC в локальное время пользователя
    return processedTimeUTC.toLocaleString()
  }

  const transformOrderManager = (orderManagers) => {
    return {
      code: orderManagers.product?.code,
      brand: orderManagers.product?.brand?.id,
      manufacture: orderManagers.product?.manufacture
    };
  }

  const productCount = (data) => {
    const transformedProducts = data?.map(transformOrderManager);
    const uniqueProducts = new Set();

    transformedProducts?.forEach(product => {
      const productString = JSON.stringify(product);
      uniqueProducts.add(productString);
    });

    return uniqueProducts.size;
  }
  return (
    <>
      {openModal && (
        <OrderItemAddToOrderProjectView
          order_project_name={orderProjectData?.name}
          order_project_id={props.id}
          activeCompany={props.activeCompany}
          setAddressesData={props.setAddressesData}
          addressesData={props.addressesData}
          state={openModal}
          setState={handleCloseAddOrderItemModal}
        />
      )}

      <Modal
        size={"100"}
        opened={openReplaceOrderItemModal}
        onClose={() => {
          setOpenReplaceOrderItemModal(false)
        }}>
        <div className="order-item-replace-container">
          {!openModalReplaceOrderItemLoading ? (
            orderProjects?.map((orderProject) => (
              <div className="order-item-replace-content">
                <p>{orderProject?.name}</p>
                <Button
                  id={orderProject?.id}
                  disabled={orderProject?.id === props.id || orderFormed}
                  onClick={(e) => {
                    onClickBtnReplaceOrderItemHandler(e.target.id)
                  }}>
                  Выбрать
                </Button>
              </div>
            ))
          ) : (
            <Loader size={20} />
          )}
          <Paginator
            className="orders-view-side-pagination"
            page={page}
            setPage={setPage}
            pages_count={pageCount}
            disabled={openModalReplaceOrderItemLoading}
          />
        </div>
      </Modal>

      {orderProjectData && props.id ? (
        <ObjectPage
          style={{
            width: "100%",
            height: "100%",
          }}
          headerTitle={
            <DynamicPageTitle
              actions={
                <>
                  <Button
                    style={{
                      border: "2px solid #0070f2",
                    }}
                    disabled={orderFormed || !Boolean(props.permissionData?.purchasing_manager || props.permissionData?.admin || props.permissionData?.manager)}
                    design="Transparent"
                    onClick={() => setOpenModal(true)}>
                    Добавить позицию в проект заказа
                  </Button>
                  <Button
                    style={{
                      border: "2px solid #0070f2",
                    }}
                    disabled={
                      isAdmin ? false :
                        !activeCompany.is_moderated ? false :
                        priceDownloaded ? false :
                      true
                    }
                    onClick={() => {
                      generateInvoice()
                    }}
                    design="Transparent"
                  // onClick={() => setOpenModal(true)}
                  >
                    Получить счёт на оплату
                  </Button>
                  <Button
                    style={{
                      border: "2px solid #0070f2",
                    }}
                    disabled={
                      !Boolean(
                        props.permissionData?.purchasing_manager ||
                        props.permissionData?.admin ||
                        props.permissionData?.manager
                      )
                    }
                    design="Transparent"
                  // onClick={() => setOpenModal(true)}
                  >
                    Загрузить подтверждение оплаты
                  </Button>
                  <Button
                    icon="refresh"
                    design="Transparent"
                    onClick={() => getProjectByID(true)}>
                    Обновить
                  </Button>
                  <Button
                    tooltip="Закрыть"
                    icon="decline"
                    design="Transparent"
                    onClick={() => {
                      props.setChoosedProject(null)
                    }}>
                    Закрыть
                  </Button>
                </>
              }
              expandedContent={
                <>
                  {headerObjectPageStripOpen && (
                    <MessageStrip
                      color="6"
                      onClose={() => {
                        setHeaderObjectPageStripOpen(false);
                      }}>
                      {orderFormed
                        ? "Заказ оформлен, ожидается подтверждение оплаты от менеджера площадки"
                        : availableToForm
                          ? "Заказ готов к оформлению"
                          : "Для оформления заказа необходимо выбрать вариант доставки для всех позиций"}
                    </MessageStrip>
                  )}
                </>
              }
              snappedContent={
                <>
                  {headerObjectPageStripOpen && (
                    <MessageStrip
                      color="6"
                      onClose={() => {
                        setHeaderObjectPageStripOpen(false);
                      }}>
                      {orderFormed
                        ? "Заказ оформлен, ожидается подтверждение оплаты от менеджера площадки"
                        : availableToForm
                          ? "Заказ готов к оформлению"
                          : "Для оформления заказа необходимо выбрать вариант доставки для всех позиций"}
                    </MessageStrip>
                  )}
                </>
              }
              header={`№ ${orderProjectData?.number} - ${orderProjectData?.name}`}
              showSubHeaderRight></DynamicPageTitle>
          }
          selectedSectionId={selectedSectionId}
          headerContentPinnable
          image="/static/exza.png"
          imageShapeCircle
          headerContent={
            <DynamicPageHeader>
              <FlexBox wrap="Wrap" style={{ alignItems: "center" }}>
                <FlexBox direction="Column">
                  <Link>+79178864756</Link>
                  <Link>invoice@exzamarket.ru</Link>
                  <Link href="https://exzamarket.ru">
                    https://exzamarket.ru
                  </Link>
                </FlexBox>
                <FlexBox direction="Column" style={{ padding: "10px" }}>
                  <Label>Набережные Челны</Label>
                  <Label>Респ. Татарстан, Россия</Label>
                </FlexBox>
              </FlexBox>
            </DynamicPageHeader>
          }>
          <ObjectPageSection
            aria-label="Общая информация"
            id="common"
            titleText="Общая информация">
            <FlexBox style={{ gap: "1rem", alignItems: "center" }}>
              <Label>Статус:</Label>
              {!orderProjectData?.formed ? (
                <Badge colorScheme="1">Формируется</Badge>
              ) : (
                <Badge colorScheme="8">Сформирован</Badge>
              )}
            </FlexBox>
            <FlexBox style={{ gap: "1rem", alignItems: "center" }}>
              <Label>Время создания:</Label>
              <Label>{orderProjectData?.create_date_time_stamp}</Label>
            </FlexBox>
            <FlexBox style={{ gap: "1rem", alignItems: "center" }}>
              <Label>Создатель</Label>
              <Label>{orderProjectData?.user}</Label>
            </FlexBox>
            <FlexBox style={{ gap: "1rem", alignItems: "center" }}>
              <Label>Количество позиций в заказе:</Label>
              <Label>{orderProjectData?.product_count}</Label>
            </FlexBox>
            <FlexBox style={{ gap: "1rem", alignItems: "center" }}>
              <Label>Количество единиц позиций в заказе:</Label>
              <Label>{orderProjectData?.order_item_count}</Label>
            </FlexBox>
            <FlexBox style={{ gap: "1rem", alignItems: "center" }}>
              <Label>Количество товаров в заказе:</Label>
              <Label>{productCount(orderProjectData?.order_managers)}</Label>
            </FlexBox>
            <FlexBox style={{ display: "flex", flexDirection: "column", gap: "1rem", alignItems: "start" }}>
              <Label style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
                Стоимость заказа {
                  orderProjectData?.project_cost ? Number(orderProjectData?.project_cost).toFixed(2) : "Не указан"}
              </Label>
              {messageStripOpen ?
                <MessageStrip onClose={() => setMessageStripOpen(false)}>
                  Платформа по умолчанию автоматически выбирает самый дешевый вариант доставки для товаров.
                  Вы можете изменить этот вариант, и сумма будет пересчитана
                </MessageStrip>
                : null}
            </FlexBox>

            <Panel
              style={{
                marginTop: "10px",
              }}
              accessibleRole="Form"
              headerLevel="H2"
              headerText="Журнал формирования заказа">
              <Timeline>
                <TimelineItem
                  icon="cart-4"
                  name={orderProjectData?.user}
                  subtitleText=" "
                  titleText="Формирование проекта заказа">
                  <Button
                    style={{
                      border: "2px solid #0070f2",
                    }}
                    disabled={
                      orderFormed ||
                      !Boolean(
                        props.permissionData?.purchasing_manager ||
                        props.permissionData?.admin ||
                        props.permissionData?.manager
                      )
                    }
                    design={ButtonDesign.Emphasized}
                    onClick={() => setOpenModal(true)}>
                    Добавить позицию в проект заказа
                  </Button>
                </TimelineItem>
                <TimelineItem
                  icon="activity-2"
                  name={orderProjectData?.formed?.creator}
                  subtitleText={
                    orderProjectData?.formed?.create_date_time_stamp || " "
                  }
                  titleText={
                    !orderFormed
                      ? "Отправка проекта заказа на оформление"
                      : "Проект заказа отправлен на оформление"
                  }>
                  <FlexBox
                    alignItems="Center"
                    style={{
                      gap: "10px",
                    }}>
                    <Button
                      disabled={orderFormed || !availableToForm}
                      onClick={() => formOrderProject()}
                      design={ButtonDesign.Emphasized}>
                      Отправить на оформление
                    </Button>
                    <FlexBox
                      style={{
                        gap: "5px",
                      }}
                      alignItems="Center">
                      <CheckBox disabled={orderFormed || !availableToForm} />
                      <Label>
                        Автоматически заказывать подтвержденные позиции
                      </Label>
                    </FlexBox>
                  </FlexBox>
                </TimelineItem>
                {orderFormed && (
                  <TimelineItem
                    icon="cart-approval"
                    name={" "}
                    subtitleText={" "}
                    titleText={
                      !orderProjectData?.allow_to_delivery
                        ? "Ожидается подтверждение позиций поставщиками"
                        : "Позиции подтверждены"
                    }>
                    <Button
                      id="openPopover"
                      onClick={() =>
                        setManagersStatusesPopover(
                          (prev) => !managersStatusesPopover
                        )
                      }
                      design={ButtonDesign.Emphasized}>
                      Статусы позиций
                    </Button>
                    {createPortal(
                      <Popover
                        headerText="Статусы позиций"
                        open={managersStatusesPopover}
                        opener="openPopover"
                        onAfterClose={() => setManagersStatusesPopover(false)}>
                        {managersStatusesList &&
                          managersStatusesList?.map((status) => (
                            <Card
                              style={{
                                margin: "5px 0",
                              }}
                              on
                              header={
                                <CardHeader
                                  avatar={<></>}
                                  status={`${status?.confirmed_positions_count} / ${status?.positions_count}`}
                                  subtitleText={status?.product_name}
                                  titleText={status?.product_code}
                                  onClick={() =>
                                    setSelectedSectionId(status?.id)
                                  }
                                />
                              }>
                              <div
                                style={{
                                  width: "90%",
                                  margin: "10px auto",
                                }}>
                                <FlexBox
                                  style={{ gap: "1rem", alignItems: "center" }}>
                                  <Label>Бренд</Label>
                                  <Label>{status?.product_brand}</Label>
                                </FlexBox>
                                <FlexBox
                                  style={{ gap: "1rem", alignItems: "center" }}>
                                  <Label>Производитель</Label>
                                  <Label>{status?.product_manufactor}</Label>
                                </FlexBox>
                                <FlexBox
                                  style={{ gap: "1rem", alignItems: "center" }}>
                                  <Label>Статус</Label>
                                  <Badge
                                    colorScheme={
                                      orderFormed
                                        ? status?.code == "OK" &&
                                          status?.order_manager_confirmed
                                          ? "8"
                                          : "1"
                                        : "10"
                                    }>
                                    {status?.code == "OK" && orderFormed
                                      ? `Подтверждено ${status?.confirmed_positions_count} / ${status?.positions_count}`
                                      : "Не оформлен"}
                                  </Badge>
                                </FlexBox>
                              </div>
                            </Card>
                          ))}
                      </Popover>,
                      document.body
                    )}
                  </TimelineItem>
                )}
                {orderFormed && (
                  <TimelineItem
                    icon="monitor-payments"
                    subtitleText=" "
                    titleText="Ожидается подтверждение оплаты от менеджера площадки">
                    <FlexBox
                      alignItems="Center"
                      style={{
                        gap: "10px",
                      }}>
                      {
                        <Button
                          disabled={
                            isAdmin ? false :
                              !activeCompany.is_moderated ? false :
                              priceDownloaded ? false :
                            true
                          }
                          // disabled={ !(
                          //   isAdmin || ((
                          //     props.permissionData?.purchasing_manager ||
                          //     props.permissionData?.admin ||
                          //     props.permissionData?.manager
                          //   ) && (priceDownloaded && activeCompany.is_moderated) || (
                          //     props.permissionData?.purchasing_manager ||
                          //     props.permissionData?.admin ||
                          //     props.permissionData?.manager
                          //   ) && (!activeCompany.is_moderated))
                          // )}
                          onClick={() => {
                            generateInvoice()
                          }}
                          design={ButtonDesign.Emphasized}>
                          Получить счёт на оплату
                        </Button>
                      }
                      {isAdmin && (
                        <Button
                          disabled={
                            !orderFormed ||
                            orderProjectData?.payment !== null ||
                            undefined
                          }
                          onClick={() => payConfirmForOrderProject()}
                          design={ButtonDesign.Emphasized}>
                          Подтвердить оплату
                        </Button>
                      )}
                    </FlexBox>
                  </TimelineItem>
                )}
                {orderProjectData?.payment && (
                  <TimelineItem
                    icon="inventory"
                    subtitleText=" "
                    titleText="Организация доставки">
                    <FlexBox
                      alignItems="Center"
                      style={{
                        gap: "10px",
                        alignItems: "center",
                      }}>
                      {
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                          }}>
                          <Button
                            disabled={
                              !orderProjectData?.delivered &
                                orderProjectData?.allow_to_delivery
                                ? "false"
                                : "true"
                            }
                            onClick={() => deliveryForOrderProject()}
                            design={ButtonDesign.Emphasized}>
                            Заказать доставку
                          </Button>
                          {deliveryError &
                            !orderProjectData?.contractor_contact ? (
                            <Badge colorScheme="2">
                              Выберите контактное лицо для доставки
                            </Badge>
                          ) : (
                            <></>
                          )}
                        </div>
                      }
                      {!orderProjectData?.allow_to_delivery ? (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "1px",
                          }}>
                          <Badge colorScheme="1" style={{ textAlign: "left" }}>
                            {"Для заказа доставки необходимо проверить: "}
                          </Badge>
                          <Badge colorScheme="1" style={{ textAlign: "left" }}>
                            {"1) Подтверждение позиций всеми поставщиками;"}
                          </Badge>
                          <Badge colorScheme="1" style={{ textAlign: "left" }}>
                            {"2) Оплату заказа;"}
                          </Badge>
                          <Badge colorScheme="1" style={{ textAlign: "left" }}>
                            {
                              "3) Выбрать ПВЗ для всех потребностей с типом доставки ПВЗ"
                            }
                          </Badge>
                        </div>
                      ) : !orderProjectData?.contractor_contact ? (
                        <Select
                          style={{ width: "20rem" }}
                          onChange={(e) => setContactInfo(e)}>
                          <Option>
                            Выберите контакт из списка доступных контактов
                          </Option>
                          {companyContacts?.map((contact) => (
                            <Option id={contact?.id} hidden={false}>
                              {`${contact?.last_name} ${contact?.first_name} ${contact?.middle_name ? contact?.middle_name : ""
                                }`}
                            </Option>
                          ))}
                        </Select>
                      ) : (
                        <Label>{`Контактное лицо: ${orderProjectData?.contractor_contact?.last_name} ${orderProjectData?.contractor_contact?.first_name} ${orderProjectData?.contractor_contact?.middle_name}`}</Label>
                      )}
                    </FlexBox>
                  </TimelineItem>
                )}
                {orderProjectData?.completed && (
                  <TimelineItem
                    icon="complete"
                    subtitleText=" "
                    titleText="Заказ выполнен"></TimelineItem>
                )}
              </Timeline>
            </Panel>
          </ObjectPageSection>

          {orderProjectData &&
            orderProjectData?.managers?.map((managerData, index) => (
              <ObjectPageSection
                aria-label={`${managerData?.product?.code} (Кол-во: ${managerData?.count})`}
                id={managerData?.id}
                titleText={`${managerData?.product?.code} (Кол-во: ${managerData?.count}) - ${formatTimeToLocal(managerData?.create_date_time_stamp)}`}
                style={{ marginBottom: orderProjectData?.managers?.length === index + 1 ? "20%" : 0 }}
              >
                <OrderManagerView
                  order={managerData}
                  orderProjectData={orderProjectData}
                  count={managerData?.count}
                  orderFormed={orderFormed}
                  getProjectByID={getProjectByID}
                  setChoosedOrderItemToReplace={setChoosedOrderItemToReplace}
                  setOpenReplaceOrderItemModal={
                    openModalReplaceOrderItemHandler
                  }
                  projectFormed={projectFormed}
                // checkOrderManagersHasDelivered={handleCheckOrderManagersHasDelivered}
                />
              </ObjectPageSection>
            ))}
        </ObjectPage>
      ) : (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}>
          {loading ? (
            <Loader size={50} />
          ) : (
            <IllustratedMessage
              style={{ margin: "0 auto" }}
              titleText={
                props.searchPage !== "searchPage"
                  ? "Выберите проект заказа из списка"
                  : "Выберите товар"
              }
              subtitleText=" "
            />
          )}
        </div>
      )}
    </>
  )
}

export default OrderProjectInfoSide
