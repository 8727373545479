import React, { useState } from "react"
import "./OrdersView.css"

import OrdersViewSide from "./orders-view-side/OrdersViewSide"
import OrderProjectInfoSide from "./order-project-info-side/OrderProjectInfoSide"
import {
  SplitterElement,
  SplitterLayout,
  ThemeProvider,
} from "@ui5/webcomponents-react"
import { useAtom } from "jotai"
import { activeCompanyAtom } from "../../hoc/GlobalAtoms"
import PermissionManager from "../base/permission_manager/PermissionManager"
import { useSearchParams } from "react-router-dom"

const OrdersView = (props) => {
  const [search, setSearch] = useSearchParams()
  const [activeCompany] = useAtom(activeCompanyAtom)
  const [choosedProject, setChoosedProject] = useState(
    search.get("project") || null
  )
  const [openProject, setOpenProject] = useState(choosedProject !== null)
  const [permissionData, setPermissionData] = useState({})

  const [isMessageHidden, setIsMessageHidden] = useState(
    localStorage.getItem('showMessage') === 'false'
  );


  return (
    <ThemeProvider>
      <SplitterLayout
        style={{
          height: "100%",
          width: "100%",
        }}>
        <SplitterElement>
          <OrdersViewSide
            activeCompany={props.activeCompany}
            setChoosedProject={setChoosedProject}
            setOpenProject={setOpenProject}
            permissionData={permissionData}
          />
        </SplitterElement>
        <SplitterElement>
          <OrderProjectInfoSide
            addressesData={props.addressesData}
            setAddressesData={props.setAddressesData}
            activeCompany={props.activeCompany}
            id={choosedProject && choosedProject}
            setChoosedProject={setChoosedProject}
            openProject={openProject}
            setOpenProject={setOpenProject}
            permissionData={permissionData}
          />
        </SplitterElement>
      </SplitterLayout>
      <PermissionManager
        keys={["admin", "manager", "purchasing_manager"]}
        setPermissionData={setPermissionData}
      />
    </ThemeProvider>
  )
}

export default OrdersView
