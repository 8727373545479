import React, { useEffect, useState, useRef } from "react";
import "./ProductSearcherWithWizardMain.css";
import ProductSearcher from "./product-searcher/ProductSearcher";
import classNames from "classnames";
import UserWizard from "./user-wizard/UserWizard";
import { ProductSearchHeader } from "./product-searcher-header/ProductSearchHeader";
import { useSearchParams, useLocation, useNavigate } from "react-router-dom";
import { checkIsAuthenticated } from "../../requests/authorization";
import {
  Icon,
  Input,
  SplitterElement,
  SplitterLayout,
  SuggestionItem,
  ThemeProvider,
} from "@ui5/webcomponents-react";
import axios_instance from "../../requests/config_defaults";
import useGeoSearcher from "../../hooks/GeoSearcher";
import { HOST_IP_ADDRESS } from "../../requests/constants";
import { useAtom } from "jotai";
import { activeCompanyAtom } from "../../hoc/GlobalAtoms";
import { geoObjectAtom } from "./user-wizard/UserWizardAtoms";

const ProductSearcherWithWizardMain = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [query, setQuery] = useState(searchParams.get("q") || "");
  const [activeProduct, setActiveProduct] = useState(
    searchParams.get("pid") || null
  );
  const [suggestions, setSuggestions] = useState([]);
  const [activeAddress, setActiveAddress] = useState("");

  const [isAuth, setIsAuth] = useState(false);
  const { searcher, geo_object, setValue } = useGeoSearcher(
    {
      width: "100%",
      gap: "10px",
    },
    ""
  );
  const [activeCompany] = useAtom(activeCompanyAtom);
  const websocket = useRef(null);
  const queryRef = useRef(query);

  const [searchError, setSearchError] = useState(false)
  const [searchErrorMessage, setSearchErrorMessage] = useState("")

  const [geoObject, setGeoObject] = useAtom(geoObjectAtom);

  const location = useLocation();
  const nav = useNavigate();

  useEffect(() => {
    websocket.current = new WebSocket(HOST_IP_ADDRESS);

    websocket.current.onopen = () => {
      console.log('WebSocket is open now.');
    };

    websocket.current.onclose = () => {
      console.log('WebSocket is closed now.');
    };

    websocket.current.onmessage = (event) => {
      console.log('WebSocket message received:', event);
      let data = JSON.parse(event.data);
      if (data?.request_id === queryRef.current) {
        if (data.data.detail) {
          setSearchError(true);
          setSearchErrorMessage(data.data.detail);
        } else {
          setSuggestions(data?.data);
        }
      }
    };

    websocket.current.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    return () => {
      websocket.current.close();
    };
  }, []);

  const sendMessage = () => {
    if (websocket.current && websocket.current.readyState === WebSocket.OPEN){
      if (query){
        let request_id = new Date().getTime();
        queryRef.current = request_id;
        const message = {
          request_id: request_id,  // Ensure this ID is unique per message
          action: 'search',         // Specify the action to be performed
          code: query,
          entity: activeCompany.id,
        };
        websocket.current.send(JSON.stringify(message));
      }
    }
  };

  const onSuggestionItemSelect = (e) => {
    setQuery(e.detail.item.attributes.text.value);
    const selectedItemId = e.detail.item.attributes.id.value
    setActiveProduct(selectedItemId);
    nav(`/search?pid=${encodeURIComponent(selectedItemId)}`)
  }

  useEffect(() => {
    setSuggestions([]);
    sendMessage();
  }, [query]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    console.log(queryParams)
    const productId = queryParams.get('pid');
    if (productId) {
      setActiveProduct(productId);
    }
  }, [location.search]);

  return (
    <ThemeProvider>
      <div className="ProductSearcherWithWizardMain__main__conatainer">
        <ProductSearchHeader
          searcher={searcher}
          geo_object={geo_object}
          query={query}
          setQuery={setQuery}
          activeProduct={activeProduct}
          setActiveProduct={setActiveProduct}
          setIsAuthenticated={setIsAuth}
          setIsOpen={setIsOpen}
          suggestions={suggestions}
          searchError={searchError}
          searchErrorMessage={searchErrorMessage}
          setSearchErrorMessage={setSearchErrorMessage}
          setSearchError={setSearchError}
        />
        <div className="ProductSearcherWithWizardMain__body">
          {!activeProduct ? (
            <div className="ProductSearcherWithWizardMain__searcher__conatainer">
              <a href="/" className="main-logo-a-search-page">
                <img
                  alt="EXZA"
                  className="main-logo-search-page"
                  src={"/static/EXZA_LOGO_NAME_slogan.svg"}
                />
              </a>
              <Input
                name="searcher"
                value={query}
                onInput={(e) => {
                  setQuery(e.target.value);
                  setSearchError(false);
                  setSearchErrorMessage("");
                }}
                icon={<Icon name="search" />}
                showClearIcon
                placeholder="Введите артикул"
                className={"ProductSearcherWithWizardMain__search"}
                showSuggestions
                valueState={searchError ? "Error" : "None"}
                valueStateMessage={searchError ? <div>{searchErrorMessage}</div> : null}
                onSuggestionItemSelect={onSuggestionItemSelect}
              >
                {suggestions &&
                  suggestions.map((suggestion) => {
                    return (
                      <SuggestionItem
                        id={suggestion?.id}
                        // description={suggestion?.tax_number}
                        icon="product"
                        text={`Артикул: ${suggestion?.code} | Наименование: ${suggestion?.name ? suggestion?.name : "Не указано"
                        } | Бренд: ${suggestion?.brand
                            ? suggestion?.brand
                            : "Не указан"
                        } | Производитель: ${
                          suggestion?.manufacture
                            ? suggestion?.manufacture
                            : "Не указан"
                        }`}
                      />
                    );
                  })}
              </Input>
            </div>
          ) : (
            <ProductSearcher
              searcher={searcher}
              geo_object={geo_object}
              activeProduct={activeProduct}
              activeAddress={activeAddress}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
              isAuth={isAuth}
              setIsOpen={setIsOpen}
            />
          )}
          <>
            {!isOpen && (
              <button
                onClick={() => setIsOpen(true)}
                id="ProductSearcherWithWizardMain-controller"
              >
                Помощь
              </button>
            )}

            {isOpen && (
              <div
                onClick={() => setIsOpen(false)}
                id="ProductSearcherWithWizardMain-overlay"
                className="ProductSearcherWithWizardMain-overlay"
              ></div>
            )}

            <div
              id="ProductSearcherWithWizardMain-layout"
              className={classNames(
                "ProductSearcherWithWizardMain-layout",
                isOpen && "ProductSearcherWithWizardMain-layout-open"
              )}
            >
              <div className="ProductSearcherWithWizardMain-layout-content">
                <div className="ProductSearcherWithWizardMain-layout-header">
                  <h3>{`Подсказка для пользователя`}</h3>
                  <div
                    onClick={() => setIsOpen(false)}
                    className="ProductSearcherWithWizardMain-layout-close-sb-btn"
                  ></div>
                </div>
                <div className="ProductSearcherWithWizardMain-content">
                  <div className="ProductSearcherWithWizardMain-container">
                    <UserWizard
                      setIsAuthenticated={setIsAuth}
                      setActiveAddress={setActiveAddress}
                      setIsOpen={setIsOpen}
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default ProductSearcherWithWizardMain;
